.container {
    width: 1920px;
    height: 1080px;
    background: linear-gradient(360deg, #C3C3C5 0%, #F1F1F1 100%);
}

.top-bar {
    width: 100%;
    height: 108px;
    background: var(--Brand-Grey, #595757);
    position: relative;
}

.top-bar .info {
    position: absolute;
    right: 100px;
    width: 860px;
    height: 108px;
    background: var(--Yellow, #FDC900);
    font-size: 32px;
    color: white;
    font-weight: 700;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.top-bar .info .weather-info {
    width: 30%;
    text-align: center;
}

.top-bar .logo {
    position: absolute;
    height: 108px;
    width: 960px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.top-bar .logo img {
    vertical-align: center;
}

.left {
    position: absolute;
    top: 236px;
    left: 145px;
}

.right {
    width: 720px;
    position: absolute;
    top: 236px;
    left: 1165px;
}

.factory-content {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.info-bar {
    box-sizing: border-box;
    width: 1000px;
    height: 112px;
    border-radius: 30px 0 30px 0;
    box-shadow: 4px 4px 20px 0px #00000040;
    background-color: #fff;
    padding: 5px;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.info-bar .info-detail {
    width: 25%;
    height: 100%;
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.info-bar .info-detail .info-title {
    width: 100%;
    font-size: 20px;
    font-weight: 400;
}

.info-bar .info-detail .info-content {
    width: 100%;
    font-size: 64px;
    font-weight: 700;
}

.video-container {
    position: relative;
    width: 1000px;
    height: 562.5px;
    background-color: #fff;
    margin-top: 20px;
    border-radius: 10px;
}

.video-container #youtube-player {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.play-btn{
    position: absolute;
    z-index: 2;
}

.status .overview {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.status .overview .circle {
    width: 240px;
    height: 240px;
    display: flex;
    position: relative;
    /*flex-wrap: wrap;*/
    /*flex-direction: column;*/
    justify-content: center;
    align-items: center;
    text-align: center;
}

/*.circle:first-child{*/
/*    background-image: url(/public/img/group1.png);*/
/*    background-size: cover; !* 使背景图片覆盖整个 div *!*/
/*    background-position: center; !* 使背景图片居中 *!*/
/*    background-repeat: no-repeat; !* 防止背景图片重复 *!*/
/*}*/

/*.circle:nth-child(2){*/
/*    background-image: url(/public/img/group2.png);*/
/*    background-size: cover; !* 使背景图片覆盖整个 div *!*/
/*    background-position: center; !* 使背景图片居中 *!*/
/*    background-repeat: no-repeat; !* 防止背景图片重复 *!*/
/*}*/

/*.circle:last-child{*/
/*    background-image: url(/public/img/group3.png);*/
/*    background-size: cover; !* 使背景图片覆盖整个 div *!*/
/*    background-position: center; !* 使背景图片居中 *!*/
/*    background-repeat: no-repeat; !* 防止背景图片重复 *!*/
/*}*/
.status .overview .circle .bgi {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    object-fit: cover; /* 使图片覆盖整个 div */
    transform: translate(-50%, -50%); /* 使图片居中 */
    z-index: 1;
}
.status .overview .circle .circle-content{
    /*width: 90px;*/
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    z-index: 2;
}
.status .overview .circle .people {
    font-size: 60px;
    font-weight: 700;
}

.status .overview .circle .people-text {
    color: #595757;
    font-size: 22px;
    font-weight: 700;
}

.factory-list {
    width: 660px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.factory {
    width: 200px;
    height: 60px;
    box-sizing: border-box;
    box-shadow: 4px 4px 20px 0px #00000040;
    background: linear-gradient(270deg, #E4B616 0%, #FEE58E 100%);
    border-radius: 20px 0 20px 0;
    display: flex;
    align-items: center;
    padding: 5px;
    margin-right: 20px;
    margin-bottom: 20px;
}

.factory .factory-detail {
    width: 60px;
    height: 50px;
    border-radius: 50px;
    background-color: #fff;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    font-weight: 600;
}

.factory .factory-name {
    width: 140px;
    font-size: 24px;
    font-weight: 500;
    text-align: center;
}

.page {
    width: 650px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.page > * {
    width: 40px;
    height: 40px;
    border-radius: 40px;
    margin: 0 10px;
    font-size: 24px;
    font-weight: 600;
}

.page-index{
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
}

.prev, .next{
    background-color: #595757;
    display: flex;
    justify-content: center;
    align-items: center;
}

.prev img, .next img {
    /*width: 50%;*/
}




